import React, { useRef, useEffect, useState } from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    .fade-in-section {
        opacity: 0;
        transform: translateY(50px); /* Initial position off-screen */
        transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    }

    .fade-in-section.is-visible {
        opacity: 1;
        transform: translateY(0); /* Slide up to final position */
    }

    .slide-up-section {
        width: 100%;
        opacity: 0;
    }

    .slide-up-section.is-visible {
        opacity: 1;
    }

    .slide-up-section {
        transition-timing-function: ease-in-out; /* Users smoothness to the animation */
    }
`

const SlideUp = (props) => {
  const {
    animationType = 'bottom', duration, delay, offset = 100, children
  } = props

  const [isVisible, setIsVisible] = useState(false);
  const slideUpRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
        }
      });
    });

    observer.observe(slideUpRef.current);

    return () => observer.disconnect(); // Clean up the observer on unmount
  }, []);

  const getTransformValue = () => {
    switch (animationType) {
      case 'right':
        return `translateX(${isVisible ? 0 : offset}px)`;
      case 'left':
        return `translateX(${isVisible ? 0 : -offset}px)`;
      case 'top':
        return `translateY(${isVisible ? 0 : -offset}px)`;
      case 'bottom':
      default:
        return `translateY(${isVisible ? 0 : offset}px)`;
    }
  };

  const transitionStyle = {
    transition: `opacity ${duration}s ease-out ${delay}s, transform ${duration}s ease-out ${delay}s`,
    transform: getTransformValue(),
  };

  return (
    <Wrapper>
      <div
        className={`slide-up-section ${isVisible ? 'is-visible' : ''} ${animationType}`}
        ref={slideUpRef}
        style={transitionStyle}
      >
        {/* Render children dynamically */}
        {children}
      </div>
    </Wrapper>
  );
};

SlideUp.defaultProps = {
  duration: .4, // Default duration in seconds
  delay: .3, // Default delay in seconds
};

export default SlideUp;
