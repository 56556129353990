import React, { useState } from "react";
import Loader from "../../../components/Loader";
import { Right } from "../../../icons/components/right";
import { Instagram } from "../../../icons/components/instagram";
import { useTranslation } from "react-i18next";
import axios from "../../../tools/axios";
import styled from "styled-components";
import SlideUp from "../../../components/SlideUp";
import {Alert, Snackbar} from "@mui/material";

const ContentBox = styled('div')`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
`

const Image = styled('div')`
    height: 520px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 24px 24px 0;
  }
`

const Soon = styled('div')`
    background: #fff;
    border-radius: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1050px) {
        input {
            font-size: 14px;
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 550px) {
        padding: 16px;
    }
`

const SoonTitle = styled('p')`
    color: #555A65 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 1250px) {
        font-size: 36px;
        line-height: 40px;
    }
    
    @media (max-width: 1050px) {
        font-size: 28px;
        line-height: 34px;
    }

    @media (max-width: 950px) {
        width: 100%;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }
`

const Form = styled('form')`
    position: relative;
    padding: 25px 36px;
    width: 50%;
    display: flex;
    align-items: self-start;
    flex-direction: column;
    gap: 20px;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #fff;
    }

    @media (max-width: 1200px) {
        width: 100%;
        button {
            width: 100%;
        }
    }
`

const Inputs = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 950px) {
        flex-direction: column;
    }
`

const Input = styled('input')`
    border-radius: 8px;
    outline: none;
    padding: 10px;
    width: 100%;
    height: 45px;
    border: none;
    background-color: #EAECF5;
`

const Title = styled('div')`
    width:100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    @media (max-width: 1316px) {
      font-size: 40px;
      }
    span {
        width: 100%;
        z-index: 1;
        color: #fff;
        background-image: url("/images/titleBack.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    img {
        position: absolute;
        top: 20%;
        @media (max-width: 1316px) {
            position: absolute;
            top: 18%;
        }
        @media (max-width: 1285px) {
            position: absolute;
            top: 20%;
        }
        @media (max-width: 1210px) {
            position: absolute;
            top: 18%;
        }
        @media (max-width: 1200px) {
            position: absolute;
        }
    }
`
const Button = styled('button')`
    width: 100%;
    height: 45px;
    background: #2055E5;
    padding: 10px 50px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    
`

const ContentFlex = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 1350px) {
        flex-direction: column;
    }
`

const Vacation = styled('div')`
    width: 40%;
    height: 312px;
    background: linear-gradient(180deg, #0D10AD 0%, #0B0C31 100%);
    padding: 24px;
    border-radius: 24px;
    /* display: flex; */
    align-items: start;
    gap: 20px;
    
    .left {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        div {
          padding: 16px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(8px);
          margin-bottom: 16px;
        }
    }

    h2 {
        width: 100%;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    p {
      color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 20px;
        margin-bottom: 20px;
    }
    a {
      width: 100%;
    }
    @media (max-width: 1400px) {
        .client {
            width: 160px;
            
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    @media (max-width: 1300px) {
        .left {
            width: 100%;
        }
        .client {
            display: none;
        }
    }

    @media (max-width: 1350px) {
        width: 100%;
        .client {
            display: block;
            width: 40%;
        }
    }

    @media (max-width: 950px) {
        height: 100%;

        .client {
            display: none;
        }

        p {
            margin-bottom: 20px;
        }
    }

    @media (max-width: 550px) {
        padding: 16px;
        gap: 0;

        a {
            width: 100%;
        }
        
        button {
            width: 100%;
        }
    }
`
const ButtonLeft = styled('button')`
    width: 100%;
    height: 45px;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px 55px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    
`
const Description = styled('div')`
    width: 60%;
    height: 312px;
    background: #fff;
    overflow: hidden;
    border-radius: 24px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    position: relative;

    .left {
        width: 60%;
        height: 100%;
        padding: 24px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
    }

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    p {
        color: #555A65;
        font-size: 15px;
    }

    .person {
        position: relative;
        right: 0;
        bottom: -28px;
    }
    
    .border-line {
        width: 100%;
        margin: 8px 0;
        border: 1px solid #D7DAE2;
    }

    @media (max-width: 1400px) {
        p {
            font-size: 13px;
        }
    }

    @media (max-width: 1350px) {
        width: 100%;

        p {
            font-size: 14px;
        }
    }

    @media (max-width: 950px) {

        .left {
            width: 100%;
        }

        flex-direction: column;
        height: 100%;
        align-items: center;

        .person {
            bottom: 0;
        }
    }

    @media (max-width: 550px) {
        .left {
            padding: 16px;
        }

        .person {
            right: 0;
        }
    }
`

const TitleLeft = styled('h2')`
  width: 100% !important;
  position: relative;
  font-family: Golos Text;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #fff;
  margin-bottom: 24px;

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 1px; 
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.17%, rgba(255, 255, 255, 0) 100%);
  }
`

const InformationContent = () => {
  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({})
  const [number, setNumber] = useState('');
  const [open, setOpen] = useState({open: false, type: ''});

  const handleChangeNumber = (e) => {
    const value = e.target.value;

    if (/^\d{0,12}$/.test(value)) {
      setNumber(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    const {name} = values
    e.preventDefault()
    await axios().post('client/application/', {'full_name': name, 'phone_number': number, is_linko: true})
      .then(() => {
        setValues({ name: '', phone: '' })
        setNumber('')
        setLoading(false)
        setOpen({open: true, type: 'success'})
      })
      .catch(() => {
        setLoading(false)
        setOpen({open: true, type: 'error'})
      })
  }

  function TransitionLeft (props) {
    return <SlideUp {...props} direction="left"/>;
  }
  const snackbarStyles = {
    'error': {
      'width': '200px',
      'zIndex': '1000000000 !important',
      'background': '#d32f2f',
      'color': '#fff',
      '& svg': {
        'fill': '#fff'
      }
    },
    'success': {
      'zIndex': '1000000000 !important',
      'width': '250px',
      'background': '#2e7d32',
      'color': '#fff',
      '& svg': {
        'fill': '#fff'
      }
    }
  };

  const snackbar = (
    <>
      {open.open && open.type === 'success' ? (
        <Snackbar
          TransitionComponent={TransitionLeft}
          open={true}
          anchorOrigin={{ 'horizontal': 'right', 'vertical': 'top' }}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          {

            <Alert
              sx={snackbarStyles.success}
              severity="success"
            >Спасибо за вашу заявку</Alert>
          }
        </Snackbar>
      ) : open.open && open.type === 'error' ? (
        <Snackbar
          TransitionComponent={TransitionLeft}
          open={true}
          onClose={(e) => this.handleClose(e)}
          anchorOrigin={{ 'horizontal': 'right', 'vertical': 'top' }}
          autoHideDuration={6000}
        >
          <Alert
            sx={snackbarStyles.error}
            severity="error"
          >Заполните поле</Alert>

        </Snackbar>
      ) : null}
    </>
  )

  return (
    <ContentBox>
      {snackbar}
      <Soon>
        <Form onSubmit={handleSubmit}>
          <Title>
            {currentLanguage === 'ru'
              ? (
                <>
                  Скоро откроется
                  <span>новый этап</span>
                  в Цифровизации с Искусственным Интеллектом!
                </>
              ) : currentLanguage === 'uz' ? (
                <>
                  Tez orada sun’iy intellekt bilan raqamlashtirishning
                  <span>yangi bosqichi</span>
                  boshlanadi!
                </>
              ) : (
                (
                  <>
                    Тез орада сунъий интеллект билан рақамлаштиришнинг
                    <span>янги босқичи</span>
                    бошланади!
                  </>
                )
              )}
          </Title>
          <SoonTitle>
            {t('write_contacts')}
          </SoonTitle>
          <Inputs>
            <Input
              name={'name'}
              value={values.name}
              placeholder={t('your_name')}
              onChange={handleChange}
            />
            <Input
              name={'phone'}
              type={'number'}
              placeholder={t('phone_number')}
              value={number}
              onChange={handleChangeNumber}
            />
          </Inputs>
          <Button type={'submit'}>
            {loading ? <Loader /> : t('send')}
          </Button>
        </Form>
        <Image>
          <img src={'/images/hero.png'} />
        </Image>
      </Soon>
      <ContentFlex>
        <Vacation>
          <div className="left">
            <div style={{height: '100%'}}>
              <TitleLeft>{t('join_us')}</TitleLeft>
              <p>{t('join_us_info')}</p>
            </div>
            <a href={'https://tashkent.hh.uz/employer/11553058/vacancies'} target={'_blank'} rel="noreferrer">
              <ButtonLeft>
                {t('look_vacations')}
                <Right />
              </ButtonLeft>
            </a>
          </div>
          {/* <div className="client">
            <Client />
          </div> */}
        </Vacation>
        <Description>
          <div className="left">
            <p style={{marginBottom: '20px'}}>{t('linko_desription')}</p>
            <div style={{ width: '100%' }}>
              <h2>{t('laziz_adxamov')}</h2>
              <div className="border-line" />
              <p style={{ fontSize: '14px', color: '#2055E5' }} className={'info'}>{t('founder_title')}</p>
              <div style={{ display: 'flex', gap: '5px', marginTop: '8px', alignItems: 'center' }}>
                <Instagram />
                <a
                  style={{ fontSize: '14px', color: '#2B2D33' }}
                  target={'_blank'}
                  href={'https://www.instagram.com/dervish1976/'} rel="noreferrer">
                  dervish1976
                </a>
              </div>
            </div>
          </div>
          <img className="person" src={'/images/mobilePerson.png'} alt={'Лазиз Адхамов'} />
        </Description>
      </ContentFlex>
    </ContentBox>
  )
}

export default InformationContent
