import React from "react";
import {useState} from "react";
import styled from "styled-components";
import i18n from 'i18next';
import _ from 'lodash'

const SelectionBox = styled('div')`
  width: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
    user-select: none;
    cursor: pointer;
    background: #fff;
    border-radius: 8px;
    padding: 12px 16px;
    color: #2B2D33;
    font-weight: 400;
`

const Select = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  color: #18214D;
  user-select: none;

  img {
    width: 20px;
    height: 20px;
  }
`

const OptionBox = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  font-weight: 600;
  overflow: hidden;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  z-index: 100000;
  top: 55px;
  position: absolute;

    @media (max-width: 650px) {
        top: 45px;
    }
`

const Option = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 10px;
  color: #18214D;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
`

const options = [
  {value: 'ru', text: 'RU', img: '/images/russia.png'},
  {value: 'uz', text: 'UZ', img: '/images/uzb.png'},
  {value: 'uz_cyrillic', text: 'ЎЗ', img: '/images/uzb.png'},
];

const Languages = () => {
  const [openSelect, setOpenSelect] = useState(false)
  const [lang, setLang] = useState({value: 'ru', text: 'RU', img: '/images/russia.png'})

  const handleChange = (item) => {
    setLang(item)
    i18n.changeLanguage(item.value);
  };

  const langText = _.get(lang, 'text')
  const langImg = _.get(lang, 'img')

  return (
    <SelectionBox onClick={() => setOpenSelect(!openSelect)}>
      <Select>
        <p>{langText}</p>
        <img src={langImg} alt={'flag'}/>
      </Select>
      <OptionBox>
        {openSelect
          ? _.map(options, (item) => {
            return (<Option onClick={() => {
              handleChange(item)
              setOpenSelect(!openSelect)
            }}>
              {item.text}
              <img alt={''} src={item.img}/>
            </Option>)
          })
          : null}
      </OptionBox>
    </SelectionBox>
  )
}

export default Languages
