import main from './main';

const locales = {
  ru: {
    translation: {
      ...main.ru,
    }
  },

  uz: {
    translation: {
      ...main.uz,
    }
  },

  uz_cyrillic: {
    translation: {
      ...main.uz_cyrillic,
    }
  }
};

export default locales;
