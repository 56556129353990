import React from "react";
import {Logo} from "../../../icons/components/logo";
import Languages from "../../../components/Languages";
import styled from "styled-components";

const Top = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 650px) {
        padding: 24px 16px 0 16px;
    }
`

const Phone = styled('div')`
    background: #fff;
    border-radius: 8px;
    padding: 12px 16px;
    color: #2B2D33;
    font-weight: 400;
`

const Navbar = () => {
  return (
    <Top>
      <Logo />
      <div style={{display: 'flex', gap: '10px'}}>
        <Phone>
          +998 78 555-55-25
        </Phone>
        <Languages />
      </div>
    </Top>
  )
}

export default Navbar
