import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import MainContent from "./MainContent";

const Wrapper = styled('div')`
  display: none;
    background: #fff;
    height: 100%;
    min-height: 100vh;

    @media (max-width: 650px) {
        display: block;
    }
`

const MobileView = () => {
  return (
    <Wrapper>
      <Navbar />
      <MainContent />
    </Wrapper>
  )
}

export default MobileView
