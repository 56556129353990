import React, {useState} from "react";
import Loader from "../../../components/Loader";
import {useTranslation} from "react-i18next";
import axios from "../../../tools/axios";
import styled from "styled-components";
import SlideUp from "../../../components/SlideUp";
import {Alert, Snackbar} from '@mui/material';

const Contact = styled('div')`
    background: linear-gradient(180deg, #0D10AD 0%, #0B0C31 100%);
    width: 100%;
    height: 320px;
    border-radius: 24px 24px 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    color: #fff;
    padding: 16px;
    z-index: 3;
    margin-top: -3%;
    
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 24px;
    }
    
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        button {
            margin-top: 20px;
        }
    }
`

const Input = styled('input')`
    border-radius: 8px;
    outline: none;
    padding: 10px;
    width: 100%;
    height: 45px;
    border: none;
`

const Button = styled('button')`
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 8px;
    
    color: #fff;
`

const MobileContact = () => {

  const {t} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({})
  const [number, setNumber] = useState('');
  const [open, setOpen] = useState({open: false, type: ''});

  const handleChangeNumber = (e) => {
    const value = e.target.value;

    if (/^\d{0,12}$/.test(value)) {
      setNumber(value);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    const {name} = values
    e.preventDefault()
    await axios().post('client/application/', {'full_name': name, 'phone_number': number, is_linko: true})
      .then(() => {
        setValues({ name: '', phone: '' })
        setNumber('')
        setLoading(false)
        setOpen({open: true, type: 'success'})
      })
      .catch(() => {
        setLoading(false)
        setOpen({open: true, type: 'error'})
      })
  }

  function TransitionLeft (props) {
    return <SlideUp {...props} direction="left"/>;
  }
  const snackbarStyles = {
    'error': {
      'width': '200px',
      'zIndex': '1000000000 !important',
      'background': '#d32f2f',
      'color': '#fff',
      '& svg': {
        'fill': '#fff'
      }
    },
    'success': {
      'zIndex': '1000000000 !important',
      'width': '250px',
      'background': '#2e7d32',
      'color': '#fff',
      '& svg': {
        'fill': '#fff'
      }
    }
  };

  const snackbar = (
    <>
      {open.open && open.type === 'success' ? (
        <Snackbar
          TransitionComponent={TransitionLeft}
          open={true}
          anchorOrigin={{ 'horizontal': 'right', 'vertical': 'top' }}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          {

            <Alert
              sx={snackbarStyles.success}
              severity="success"
            >Спасибо за вашу заявку</Alert>
          }
        </Snackbar>
      ) : open.open && open.type === 'error' ? (
        <Snackbar
          TransitionComponent={TransitionLeft}
          open={true}
          onClose={(e) => this.handleClose(e)}
          anchorOrigin={{ 'horizontal': 'right', 'vertical': 'top' }}
          autoHideDuration={6000}
        >
          <Alert
            sx={snackbarStyles.error}
            severity="error"
          >Заполните поле</Alert>

        </Snackbar>
      ) : null}
    </>
  )

  return (
    <Contact>
      {snackbar}
      <p style={{textAlign: 'center'}}>{t('write_contacts')}</p>
      <form onSubmit={handleSubmit}>
        <Input
          name={'name'}
          value={values.name}
          placeholder={t('your_name')}
          onChange={handleChange}
        />
        <Input
          name={'phone'}
          type={'number'}
          placeholder={t('phone_number')}
          value={number}
          onChange={handleChangeNumber}
        />
        <Button>
          {loading ? <Loader /> : t('send')}
        </Button>
      </form>
    </Contact>
  )
}

export default MobileContact
