export default {
  ru: {
    soon_title: 'Скоро откроется новый этап в автоматизации!',
    write_contacts: 'Оставьте свои контакты, и мы сообщим вам о запуске!',
    your_name: 'Ваше имя',
    phone_number: 'Номер телефона',
    send: 'Отправить',
    vacations: 'Вакансии',
    vacations_title: 'Мы ищем талантливых специалистов, готовых развиваться вместе с нами и создавать инновационные решения для автоматизации.',
    look: 'Посмотреть',
    look_vacations: 'Посмотреть вакансии',
    linko_desription: 'Софт, который поменяет правила игры на рынке автоматизации ритейла. Мы вложили в него весь свой опыт и экспертизу, чтобы наши пользователи стали увереннее в себе.',
    laziz_adxamov: 'Лазиз Адхамов',
    founder: 'Основатель',
    founder_title: 'Основатель - SAP Uzbekistan, Book Cafe, Top Contact',
    join_us: 'Давайте строить будущее вместе!',
    join_us_info: 'Мы ищем талантливых специалистов, готовых развиваться вместе с нами и создавать инновационные решения для автоматизации.',
  },
  uz: {
    soon_title: 'Tez orada avtomatlashtirishda yangi bosqich ochiladi!',
    write_contacts: 'Kontaktlaringizni qoldiring va biz sizga ishga tushishi haqida xabar beramiz!',
    your_name: 'Ismingiz',
    phone_number: 'Telefon raqamingiz',
    send: 'Yuborish',
    vacations: 'Vakansiyalar',
    vacations_title: 'Bizning jamoaga qo‘shiling! Biz avtomatlashtirish uchun innovatsion yechimlarni yaratishga va birgalikda rivojlanishga tayyor bo‘lgan iste’dodli mutaxassislarni izlayapmiz.',
    look: 'Ko‘rish',
    look_vacations: 'Vakansiyalarni ko‘rish',
    linko_desription: 'Retail avtomatlashtirish bozorida o‘yin qoidalarini o‘zgartiradigan dastur. Biz foydalanuvchilarimiz ishonchini qozonish uchun o’z tajribamiz va bilimlarimizni shu dasturning ichiga joylashtirdik.',
    laziz_adxamov: 'Laziz Adxamov',
    founder: 'Asoschisi',
    founder_title: 'SAP Uzbekistan, Book Cafe, Top Contact - Asoschisi',
    join_us: 'Keling, kelajakni birgalikda quramiz!',
    join_us_info: 'Biz avtomatlashtirish uchun innovatsion yechimlarni yaratishga va birgalikda rivojlanishga tayyor bo‘lgan iste’dodli mutaxassislarni izlayapmiz.',
  },
  uz_cyrillic: {
    soon_title: 'Тез орада автоматлаштиришда янги босқич очилади!',
    write_contacts: 'Контактларингизни қолдиринг ва биз сизга ишга тушиши ҳақида хабар берамиз!',
    your_name: 'Исмингиз',
    phone_number: 'Телефон рақамингиз',
    send: 'Юбориш',
    vacations: 'Вакансиялар',
    vacations_title: 'Бизнинг жамоага қўшилинг! Биз автоматлаштириш учун инновацион ечимларни яратишга ва биргаликда ривожланишга тайёр бўлган истеъдодли мутахассисларни излаяпмиз.',
    look: 'Кўриш',
    look_vacations: 'Вакансияларни кўриш',
    linko_desription: 'Retail автоматлаштириш бозорида ўйин қоидаларини ўзгартириб юборувчи дастур. Биз фойдаланувчиларимиз ишончини қозониш учун ўз тажрибамиз ва билимларимизни шу дастурнинг ичига жойлаштирдик.',
    laziz_adxamov: 'Лазиз Адҳамов',
    founder: 'Асосчиси',
    founder_title: 'SAP Uzbekistan, Book Cafe, Top Contact - Асосчиси',
    join_us: 'Келинг, келажакни биргаликда қурайлик!',
    join_us_info: 'Биз автоматлаштириш учун инновацион ечимларни яратишга ва биргаликда ривожланишга тайёр бўлган истеъдодли мутахассисларни излаяпмиз.',
  }
}
