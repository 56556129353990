import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import locales from "./locales";
import Privacy from "./pages/Privacy/Privacy";

i18n
  .use(initReactI18next)
  .init({
    resources: { ...locales },
    lng: 'ru',
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false
    }
  });

function App() {
  return (
    <div className="App" style={{height: '100%'}}>
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/privacy-policy'} element={<Privacy />} />
      </Routes>
    </div>
  );
}

export default App;
