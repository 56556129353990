import React from "react";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import InformationContent from "./components/InformationContent";
import MobileView from "./MobileView/MobileView";

const Wrapper = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 120px 22px;
    background: linear-gradient(360deg, rgba(39, 40, 128, 0.26) 0%, rgba(203, 203, 241, 0.4) 100%);
    z-index: 2;

    @media (max-width: 1050px) {
        input {
            font-size: 14px;
        }
    }

    @media (max-width: 950px) {
        padding: 22px 60px 80px;
    }

    @media (max-width: 650px) {
        padding: 16px;
        display: none;
    }
`

// const Background = styled('div')`
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
//     background-image: url('/background.png');
//     background-size: cover;
//     background-position: center center;
//     background-repeat: no-repeat;
// `

const WrapContent = styled('div')`
    z-index: 2;
    width: 1200px;
    height: 100%;

    @media (max-width: 1250px) {
        width: 100%;
    }
`

const BorderLine = styled('div')`
    border: 1px solid #fff;
    opacity: 50%;
    margin: 30px 0;

    @media (max-width: 550px) {
        margin: 11px 0;
    }
`

const Home = () => {
  return (
    <>
      <Wrapper>
        {/* <Background /> */}
        <WrapContent>
          <Navbar />
          <BorderLine />
          <InformationContent />
        </WrapContent>
      </Wrapper>
      <MobileView />
    </>
  )
}

export default Home
