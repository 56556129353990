import React from "react";
import styled from "styled-components";
import {Right} from "../../../icons/components/right";
import {useTranslation} from "react-i18next";
import MobileContact from "./MobileContact";

const Wrapper = styled('div')`
    margin-top: 48px;
`

const TitleContent = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    .background {
        width: 389px;
        height: 245px;
    }
`

const Title = styled('div')`
    width: 343px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
    span {
        width: 100%;
        z-index: 1;
        color: #fff;
        background-image: url("/images/titleBack.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    img {
        position: absolute;
        top: 11%;
    }
`

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    margin-top: -30px;
`

const Vacation = styled('div')`
    background: linear-gradient(180deg, #0D10AD 0%, #0B0C31 100%);
    width: 100%;
    height: 340px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    color: #fff;
    padding: 16px;
    z-index: 1;

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 12px 0;
        
        h2 {
            text-align: center;
            width: 280px;
            font-size: 24px;
            line-height: 28px;
            font-weight: 700;
        }
        
        p {
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }

    a {
        width: 100%;
    }
`

const Button = styled('button')`
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 8px;
    
    color: #fff;
`

const Description = styled('div')`
    background: rgba(234, 236, 245, 1);
    width: 100%;
    padding: 24px 16px 0 16px;
    gap: 12px;
    border-radius: 24px 24px 0 0;
    z-index: 2;
    margin-top: -12%;

    display: flex;
    align-items: center;
    flex-direction: column;

    .border-line {
        width: 100%;
        margin: 8px 0;
        border: 1px solid #D7DAE2;
    }

    h2 {
        text-align: start;
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
    }
    
    p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        color: rgba(85, 90, 101, 1);
    }

    a {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        color: #2B2D33;
    }
`

const MainContent = () => {
  const {t, i18n} = useTranslation()
  const currentLanguage = i18n.language

  return (
    <Wrapper>
      <TitleContent>
        <Title>
          {currentLanguage === 'ru'
            ? (
              <>
                Скоро откроется
                <span>новый этап</span>
                в Цифровизации с Искусственным Интеллектом!
              </>
            ) : currentLanguage === 'uz' ? (
              <>
                Tez orada sun’iy intellekt bilan raqamlashtirishning
                <span>yangi bosqichi</span>
                boshlanadi!
              </>
            ) : (
              (
                <>
                  Тез орада сунъий интеллект билан рақамлаштиришнинг
                  <span>янги босқичи</span>
                  бошланади!
                </>
              )
            )}
        </Title>
        <img className="background" src={'/images/mobileBack.png'} alt={'back'}/>
      </TitleContent>
      <Column>
        <Vacation>
          <div className="info">
            <h2>{t('join_us')}</h2>
            <img src={'/images/line.svg'} alt={'line'}/>
            <p>{t('join_us_info')}</p>
          </div>
          <a href={'https://tashkent.hh.uz/employer/11553058/vacancies'} target={'_blank'} rel="noreferrer">
            <Button>
              <span style={{marginRight: '8px'}}>{t('look_vacations')}</span>
              <Right />
            </Button>
          </a>
        </Vacation>
        <Description>
          <div>
            <h2>{t('laziz_adxamov')}</h2>
            <div className="border-line"/>
            <p style={{color: '#2055E5'}} className={'info'}>{t('founder_title')}</p>
            <div style={{display: 'flex', gap: '5px', margin: '8px 0', alignItems: 'center'}}>
              <img src={'/images/instagram.png'} alt={'instagram'}/>
              <a
                target={'_blank'}
                href={'https://www.instagram.com/dervish1976/'} rel="noreferrer">
                dervish1976
              </a>
            </div>
            <p>{t('linko_desription')}</p>
          </div>
          <img src={'/images/mobilePerson.png'} alt={'person'}/>
        </Description>
        <MobileContact />
      </Column>
    </Wrapper>
  )
}

export default MainContent
